import './AboutSection.scss';
import React, { forwardRef } from 'react';

const AboutSection = forwardRef(({aboutRef}, ref) => {

  return (
    <section className='about-container' ref={ref}>
      <div className='about'>
        <h1 className='about_title'>ABOUT ME</h1>
      </div>

      <div className='skill'>
        <div className='skill_card'>
          <div className='skill_card_responsive'/>
          <h2>Responsive</h2>
          <p className='skill_card_desc'>My layouts will work on any device, big or small.</p>
        </div>

        <div className='skill_card'>
          <div className='skill_card_speed'/>
          <h2>Fast</h2>
          <p className='skill_card_desc'>Fast load times and lag free interaction, my highest priority.</p>
        </div>

        <div className='skill_card'>
          <div className='skill_card_intuitive'/>
          <h2>Intuitive</h2>
          <p className='skill_card_desc'>Strong preference for easy to use, intuitive UX/UI.</p>
        </div>

        <div className='skill_card'>
          <div className='skill_card_dynamic'/>
          <h2>Dynamic</h2>
          <p className='skill_card_desc'>Websites don't have to be static, I take pride making pages come to life.</p>
        </div>
      </div>

      <div className='profile'>
        <div className='profile_card'>
          <div className='profile_card_person'/>
          <h2>Who's the guy?</h2>
          <p>I'm a Front-End Developer looking for work currently living in Ottawa, ON. I have a serious passion for UI effects, animations and creating intuitive, memeroable user experiences. </p>
          <button className='profile_card_button'>Let's create something special.</button>
        </div>
        <div className='profile_skills'>
          <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>HTML5</div>
            <div className='profile_skills_card_background'/>
            <p className='profile_skills_card_percentage'>90%</p>
          </div>
          <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>CSS3</div>
            <div className='profile_skills_card_background'/>
            <p className='profile_skills_card_percentage'>90%</p>
          </div>
          <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>JavaScript</div>
            <div className='profile_skills_card_background-80'/>
            <p className='profile_skills_card_percentage-80'>80%</p>
          </div>
          <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>React</div>
            <div className='profile_skills_card_background-85'/>
            <p className='profile_skills_card_percentage-85'>85%</p>
          </div>
          <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>Node.js</div>
              <div className='profile_skills_card_background-70'/>
              <p className='profile_skills_card_percentage-70'>70%</p>
            </div>
            <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>UI Design</div>
              <div className='profile_skills_card_background-60'/>
              <p className='profile_skills_card_percentage-60'>60%</p>
            </div>
            <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>MYSQL</div>
              <div className='profile_skills_card_background-75'/>
              <p className='profile_skills_card_percentage-75'>75%</p>
            </div>
            <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>Git</div>
              <div className='profile_skills_card_background-80'/>
              <p className='profile_skills_card_percentage-80'>80%</p>
            </div>
            <div className='profile_skills_card'>
            <div className='profile_skills_card_title'>Figma</div>
              <div className='profile_skills_card_background-85'/>
              <p className='profile_skills_card_percentage-85'>85%</p>
            </div>
            

          </div>
        </div>
        
      </section>
    );
  });
  
  export default AboutSection;