import './Footer.scss';
import React from 'react'


function Footer() {

    return (
       <footer className='footer'>
         <div className='footer_up' />
          <div className='footer_social'>
            <a href='https://www.linkedin.com/in/lucasoctavianorodrigues/'><div className='footer_social_linkedin'/></a>
            <a href='https://github.com/lucasgelinstiques'><div className='footer_social_github'/></a>
            <a href='https://www.instagram.com/lucasgelinstiques/'><div className='footer_social_instagram'/></a>
            <a href='https://www.facebook.com/lucasoctavianogelinstiques/'><div className='footer_social_facebook'/></a>
          </div>
          <p className='footer_name'>Lucas Rodrigues <bold className='footer_name_copyright'>© 2023</bold></p>
       </footer>
    );
  }
  
  export default Footer;