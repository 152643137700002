import './ContactSection.scss';
import React, { forwardRef } from 'react';
import ContactForm from '../ContactForm/ContactForm';

const ContactSection = forwardRef(({contactRef}, ref) => {
  
  return (
    <section className='contact-container' ref={ref}>
      <div className='contact'>
        <h1 className='contact_title' id='contact_section'>CONTACT</h1>
        <ContactForm />
      </div>
    </section>
  );
});

export default ContactSection;
