import React from 'react';
import StarBackground from '../../components/StarBackground/StarBackground';
import LandingPageContent from '../../components/LandingPageContent/LandingPageContent';

export default function LandingPage() {
  document.title = "LGR | Welcome"

  return (
   <>
   <StarBackground />
   <LandingPageContent />
   </>
  )
  
}
