import './SpaceGame.scss';
import React from 'react';


function SpaceGame() {
    return (
    <>
    <div className='sc' >
        <div className='sc_player'/>
        <div className='sc_aliens'>
            <div className='sc_alien-row-1'>
                <div className='sc_alien-1'/>
                <div className='sc_alien-2'/>
            </div>
            <div className='sc_alien-row-2'>
                <div className='sc_alien-3'/>
                <div className='sc_alien-4'/>
                <div className='sc_alien-5'/>
                <div className='sc_alien-6'/>
                <div className='sc_alien-7'/>
                <div className='sc_alien-8'/>
                <div className='sc_alien-9'/>
                <div className='sc_alien-10'/>
            </div>
            <div className='sc_alien-row-2'>
                <div className='sc_alien-11'/>
                <div className='sc_alien-12'/>
                <div className='sc_alien-13'/>
                <div className='sc_alien-14'/>
                <div className='sc_alien-15'/>
                <div className='sc_alien-16'/>
                <div className='sc_alien-17'/>
                <div className='sc_alien-18'/>
            </div>
            <div className='sc_alien-row-3'>
                <div className='sc_alien-19'/>
                <div className='sc_alien-20'/>
                <div className='sc_alien-21'/>
                <div className='sc_alien-22'/>
                <div className='sc_alien-23'/>
                <div className='sc_alien-24'/>
                <div className='sc_alien-25'/>
                <div className='sc_alien-26'/>
            </div>
        </div>
    </div>
    </>
    );
  }
  
  export default SpaceGame;