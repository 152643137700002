import './HomePage.scss';
import React, { useRef } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AboutSection from '../../components/AboutSection/AboutSection';
import PortfolioSection from '../../components/PortfolioSection/PortfolioSection';
import ContactSection from '../../components/ContactSection/ContactSection';

function HomePage() {
  const aboutRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      <Header
        onAboutClick={() => aboutRef.current.scrollIntoView({ behavior: 'smooth' })}
        onPortfolioClick={() => portfolioRef.current.scrollIntoView({ behavior: 'smooth' })}
        onContactClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })}
        aboutRef={aboutRef}
        portfolioRef={portfolioRef}
        contactRef={contactRef}
      />
      <AboutSection ref={aboutRef} />
      <PortfolioSection ref={portfolioRef} />
      <ContactSection ref={contactRef} />
      <Footer />
    </>
  );
}

export default HomePage;
