import './Header.scss';
import React from 'react';
import { Link } from 'react-router-dom';

function Header({ onAboutClick, onPortfolioClick, onContactClick, aboutRef, portfolioRef, contactRef }) {
  const handleAboutClick = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    onAboutClick && onAboutClick();
  };

  const handlePortfolioClick = () => {
    portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
    onPortfolioClick && onPortfolioClick();
  };

  const handleContactClick = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
    onContactClick && onContactClick();
  };

  return (
    <header className='header-container'>
      <nav className='nav'>
        <ul className='nav_list'>
          <li className='nav_list_link'>
            <Link className="nav_list_link-home" to="/">HOME</Link>
          </li>
          <li className='nav_list_link' onClick={handleAboutClick}>
            ABOUT
          </li>
          <li className='nav_list_link' onClick={handlePortfolioClick}>
            PORTFOLIO
          </li>
          <li className='nav_list_link' onClick={handleContactClick}>
            CONTACT
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
