import './PortfolioSection.scss';
import React, { forwardRef } from 'react';

const PortfolioSection = forwardRef(({portfolioRef}, ref) => {


    return (
       <section className='portfolio-container' ref={ref}>
        <div className='portfolio'>
          <h1 className='portfolio_title'>PORTFOLIO</h1>
        </div>
        <div className='project'>
          <div className='project_cs'>
          </div>
          <div className='project_ts'>
          </div>
          <div className='project_bf'>
          </div>
          <div className='project_bs'>
          </div>
          <div className='project_is'>
            
          </div>
          <div className='project_tag'>
          </div>
        </div>
       </section>
    );
  });
  
  export default PortfolioSection;