import './ContactForm.scss';
import { Link , useNavigate } from 'react-router-dom';
import TextInput from '../TextInput/TextInput'


function ContactForm() {
  const navigate = useNavigate()

    return (
        <form className='signup-form' onSubmit='' noValidate >
        <div className='signup-form__details-container'>
          <section className='signup-form__details--first'>
            <h4 className='signup-form__heading'>Have a question or want to collaborate?</h4>
            <TextInput type='small' label='Name' name={'cName'} />
            <TextInput type='small' label='Email' name={'email'} />   
            <TextInput type='large' label='Your message' name={'email'} />   
            <button className='signup-form__submit-button'>SUBMIT</button>
        </section>
         
        </div>
      </form>
    );
  }
  
  export default ContactForm;

// import React, { useState, useNavigate } from 'react';
// import './ContactForm.scss';
// import TextInput from '../TextInput/TextInput';

// function ContactForm() {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     // Send email using an email sending library or service
//     // Pass in form data (name, email, message) to the email sending function

//     try {
//       // Example using Nodemailer
//       const nodemailer = require('nodemailer');
//       const transporter = nodemailer.createTransport({
//         service: 'gmail',
//         auth: {
//           user: 'lucasoctavianorodrigues@gmail.com', // your email address
//           pass: 'password' // your email password
//         }
//       });

//       const mailOptions = {
//         from: 'your-email@gmail.com',
//         to: 'lucasoctavianorodrigues@gmail.com',
//         subject: 'New message from contact form',
//         text: `Name: ${name}\nEmail: ${email}\nMessage: ${message}`
//       };

//       const info = await transporter.sendMail(mailOptions);
//       console.log(`Message sent: ${info.messageId}`);

//       // Clear form fields after successful submission
//       setName('');
//       setEmail('');
//       setMessage('');

//       // Redirect to a success page or show a success message
//       navigate('/');
//     } catch (error) {
//       console.log(`Error sending email: ${error}`);

//       // Handle any errors that may occur during the email sending process
//       // Redirect to an error page or show an error message
//       navigate('/');
//     }
//   };

//   return (
//     <form className='signup-form' onSubmit={handleSubmit} noValidate>
//       <div className='signup-form__details-container'>
//         <section className='signup-form__details--first'>
//           <h4 className='signup-form__heading'>Have a question or want to collaborate?</h4>
//           <TextInput type='small' label='Name' name='name' value={name} onChange={(e) => setName(e.target.value)} />
//           <TextInput type='small' label='Email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />   
//           <TextInput type='large' label='Your message' name='message' value={message} onChange={(e) => setMessage(e.target.value)} />   
//           <button className='signup-form__submit-button' type='submit'>SUBMIT</button>
//         </section>
//       </div>
//     </form>
//   );
// }

// export default ContactForm;
