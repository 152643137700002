import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingPage from './pages/LandingPage/LandingPage';
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import HomePage from './pages/HomePage/HomePage';
import GamePage from './pages/GamePage/GamePage';


function App() {
  return (
    <BrowserRouter>
      <main>
        <Routes>

          <Route path="/" element={<LandingPage />}/> 
          <Route path='/home' element={<HomePage />}/> 
          <Route path='/itsokaytowastesometime' element={<GamePage />} />


          {/* Page Not Found */}
          <Route path="*" element={<NotFoundPage /> } />

        </Routes>
      </main>
    </BrowserRouter>
  
  );
}

export default App;
