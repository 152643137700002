import './GamePage.scss';
import React from 'react';
import StarBackground from '../../components/StarBackground/StarBackground';
import SpaceGame from '../../components/SpaceGame/SpaceGame'
import GameContent from '../../components/GameContent/GameContent';


function GamePage() {
    return (
    <>
      <StarBackground />
      <SpaceGame />
      <GameContent />
       </>
    );
  }
  
  export default GamePage;