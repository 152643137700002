import './GameContent.scss';
import React from 'react'
import { useNavigate } from 'react-router-dom';


function GameContent() {
    const navigate = useNavigate();

    return (
       <section className='game'>
            <h1 className='game_title'> GALUCAS</h1>
                <div className='game_buttons'>
                    <button className='game_buttons-1'>PLAY</button>
                    <a href='https://lucasrodrigues.io/home'><p className='game_buttons_link'>This game is currently being worked on and should be playable by the end of May 2023. Click here to be redirected to the website.</p></a>
                </div>
       </section>
    );
  }
  
  export default GameContent;