import './LandingPageContent.scss';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import galagaTheme from "../../assets/audio/GalagaTheme.mp3";


function LandingPageContent() {
    const navigate = useNavigate();
    const audio = new Audio(galagaTheme);

    const onPlayClick = () => {
        navigate('/itsokaytowastesometime');
        audio.play();
    };

    return (
       <section className='landing'>
            <h1 className='landing_title'>Hello, I'm <bold className='landing_title_name'>Lucas Rodrigues</bold>. I'm a full-stack web developer. </h1>
                <div className='landing_buttons'>
                    <button onClick={onPlayClick} className='landing_buttons-1'>Fight diabolically evil space aliens</button>
                    <button onClick={() => navigate('/home')} className='landing_buttons-2'>Who is this guy?</button>
                </div>
       </section>
    );
  }
  
  export default LandingPageContent;